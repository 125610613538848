// Here you can add other styles
@import 'loading';
@import 'pagination';
@import 'datepicker';

td {
	vertical-align: middle;
}

td span {
	vertical-align: -webkit-baseline-middle;
}

.text-right {
	text-align: right;
}

.modal {
	background: rgba(0,0,0,0.3);
}

.table-responsive {
	border-radius: 0.375rem;
    border: 1px solid #d2d2d6;
}

.table {
	margin: 0;
}

.card-header {
	font-weight: 500;
}

.card .accordion-button::after {
    position: absolute;
    top: 12px;
    right: 12px;
    box-shadow: none;
	color: inherit;
}

.card .accordion-button {
	padding: 0;
    background-color: transparent;
	color: inherit;
}

.card .card-header {
	width: 100%;
}

.card .accordion-body {
	padding: 0;
}

.btn-primary, .btn-secondary, .btn-success, .btn-danger, .btn-warning, .btn-info {
	color: #fff !important;
}

.eye-toggler {
	position: absolute;
	right: 25px;
	margin-top: -32px;
	cursor: pointer;
}

.eye-toggler:hover {
	opacity: 0.7;
}

.form-switch {
	margin-top: 35px !important;
}

.form-switch .form-check-input {
	width: 3em !important;
	height: 1.5em !important;
}

.form-switch .form-check-label {
	margin-left: 10px;
    margin-top: 2px;
}