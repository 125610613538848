.pagination {
	margin-top: 20px;
	justify-content: center;
	align-items: baseline;
}

.pagination li {
	margin: 5px;
}

.page-link {
	border-radius: 10px;
	padding: 6px 13px;
	cursor: pointer;
}

.not-page-link {
	font-weight: bold;
	font-size: 16px;
}

.page-item:first-child .page-link {
	margin-left: 0;
	border-radius: 10px !important;
}

.page-item:last-child .page-link {
	margin-left: 0;
	border-radius: 10px !important;
}

button.page-link {
	background: #fafafa;
	color: #666;
	border: 1px solid #dee2e6;
}

button.page-link:hover {
	color: #0056b3 !important;
}

button.page-link:disabled {
	opacity: 0.5;
}

/*.pagination {
    margin: 30px 35px 0;
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
  
.pageInfo {
    color: #a0a3bd;
    font-size: 0.874em;
    letter-spacing: 0.5px;
}
  
.pageButtons {
    display: flex;
}
  
.pageBtn {
    border: 1px solid  #0062cc;
    color:  #005cbf;
    border-radius: 7px;
    margin: 2px;
    width: 35px;
    height: 25px;
    font-weight: normal;
    font-size: 10px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

.activeBtn {
    border: 1px solid blue;
    color: blue;
    background-color: transparent;
}
  
.disabledPageBtn {
    background-color: #a0a3bd;
    cursor: not-allowed;
    opacity: 0.5;
}*/

@media (min-width: 768px) {
	.pagination-pages-container div {
		width: 120px;
	}

	.pagination-rows-container div {
		width: 120px;
		float: right;
	}
}
