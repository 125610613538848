.loading-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 10000;
}

.loading-overlay .loader {
	left: 50%;
	position: absolute;
	top: 50%;
	z-index: 1999;
	color: #b0b0b0;
	width: 50px;
	height: 50px;
}

.progress-loader {
	left: 40%;
	margin-top: -20px;
	position: absolute;
	top: 50%;
	width: 24%;
	z-index: 1000;
}

.progress {
	border-radius: 5px;
	height: 0.3rem;
}