.input-group.date {
	position: relative !important;
	display: table !important;
	border-collapse: separate !important;
}

.input-group-addon {
	padding: 6px 12px !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 1 !important;
	color: #555 !important;
	text-align: center !important;
	border: 1px solid #ccc !important;
}

.input-group-addon,
.input-group-btn {
	width: 1% !important;
	white-space: nowrap !important;
	vertical-align: middle !important;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
	display: table-cell !important;
}

.input-group-addon:first-child {
	border-right: 0 !important;
}

.datepicker {
	direction: ltr !important;
}

.react-datepicker__header {
	background-color: #fff !important;
	border-bottom: 0px !important;
}

/*.react-datepicker {
	font-family: 'zekton' !important;
}*/

.react-datepicker__day--outside-month {
	color: #999 !important;
}

.react-datepicker__day-name {
	text-transform: capitalize !important;
	font-weight: bold !important;
}

.react-datepicker__month {
	margin: 0 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	margin: 0.07rem !important;
}

.datepicker {
	border-radius: 0 !important;
	border-top-right-radius: 0.375rem !important;
	border-bottom-right-radius: 0.375rem !important;
}

div.react-datepicker-popper {
	z-index: 1051 !important;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
	padding: 10px 0;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 3.5rem;
    margin: 2px;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    /* background-color: #216ba5; */
	background-color: transparent !important;
    color: #6b6d6b !important;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 0px !important;
    font-size: 15px !important;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "x";
}

.react-datepicker__navigation {
	top: 8px !important;
}